import React from 'react';

function Preloader()
{
    return(
        <div className="loader">
        <div className="loader-wrapper">
          <div className="text-center w-100 pb-2">
            <img
              className="img-preloader-1"
              alt="loader"
              src={require("./assets/img/brand/preloader.gif").default}
            />
          </div>
          <div className="text-center w-100  pt-2">
            <img
              alt="loader"
              src={require("./assets/img/brand/line-balls-preloader.gif").default}
            />
          </div>
        </div>
      </div>
    );
}
export default Preloader;
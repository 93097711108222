import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import "./assets/css/admin-dashboard.css";
// import AdminDashboard from "views/examples/AdminDashboard";
// import Master from "views/examples/Master";
import loadable from "react-loadable";
import Preloader from "Preloader";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const AdminLayout = loadable({
  loader: () => import(/*webpackChunkName:"AdminLayout" */ "./layouts/Admin"),
  loading: () => (
    <Preloader />
  ),
});

const AdminDashboard = loadable({
  loader: () =>
    import(
      /*webpackChunkName:"AdminDashboard" */ "./views/examples/AdminDashboard"
    ),
  loading: () => (
    <Preloader />
  ),
});

// const ChangePassword = loadable({
//   loader: () =>
//     import(
//       /*webpackChunkName:"ChangePassword" */ "./views/examples/ChangePassword"
//     ),
//   loading: () => (
//     <Preloader />
//   ),
// });

let AuthLayout = loadable({
  loader: () => import(/*webpackChunkName:"AuthLayout" */ "./layouts/Auth"),
  loading: () => (
    <Preloader />
  ),
});

let AdminDoctorLayout = loadable({
  loader: () =>
    import(/*webpackChunkName:"AdminDoctorLayout" */ "./layouts/AdminDoctor"),
  loading: () => (
    <Preloader />
  ),
});

// let Master = loadable({
//    loader: () =>
//      import(/*webpackChunkName:"Master" */ "./views/examples/Master"),
//   loading: () => (
//      <Preloader/>
//   ),
//  });

function App() {
  return (
    <div>
      <BrowserRouter>
        <Switch>
          <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
          <Route path="/admin" render={(props) => <AdminDashboard {...props} />} />
          <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
          <Route path="/AdminDoctor" render={(props) => <AdminDoctorLayout {...props} />}/>
          <Redirect from="/" to="/admin/AdminDashboard" />
          {/* <Redirect from="/" to="/admin/AdminDoctor" /> */}
        </Switch>
      </BrowserRouter>
      <ToastContainer position="bottom-right" />
    </div>
  );
}

export default App;
